import { WebAuth } from 'auth0-js';
import axios from 'axios';

// Turbulent Research - development environment configuration - start
// const clientId = 'K3uV9TZVikhkJuee13nGx9pRliTNDZit';
// const userDb = 'propipe-db';
// const authDomain = 'propipe-dev.us.auth0.com';

// const auth0: WebAuth = new WebAuth({
//     domain: authDomain,
//     clientID: clientId,
//     audience: 'https://propipe-api.com/', // NOTE: that trailing slash is very important
//     responseType: 'token',
//     redirectUri: process.env.VUE_APP_REDIRECT_URI,
//     // ask for all roles and user profile, jwt will only be signed with roles that have been assigned
//     scope: 'openid profile email admin:propipe admin:company user:company',
// });

// Turbulent Research - development environment configuration - end

// Turbulent Research - production environment configuration - start
const clientId = 'L69Nz89LMFmmjLbNHyyi6kXZMlzYw8kV';
const userDb = 'propipe-db';
const authDomain = 'apex-login.piggingsmarter.com';

const auth0: WebAuth = new WebAuth({
    domain: authDomain,
    clientID: clientId,
    audience: 'https://propipe-api.com/', // NOTE: that trailing slash is very important
    responseType: 'token',
    redirectUri: process.env.VUE_APP_REDIRECT_URI,
    // ask for all roles and user profile, jwt will only be signed with roles that have been assigned
    scope: 'openid profile email admin:propipe admin:company user:company',
});

// Turbulent Research - production environment configuration - end

// Turbulent Research - test environment configuration - start
// const clientId = '8xxSWl4B6Pt79I3O86wXPLM1kS8uhdDP';
//  const userDb = 'propipe-db';
//  const authDomain = 'propipe-test.us.auth0.com';

//  const auth0: WebAuth = new WebAuth({
//     domain: authDomain,
//     clientID: clientId,
//     audience: 'https://propipe-api.com/', // NOTE: that trailing slash is very important
//     responseType: 'token',
//     redirectUri: process.env.VUE_APP_REDIRECT_URI,
//     // ask for all roles and user profile, jwt will only be signed with roles that have been assigned
//     scope: 'openid profile email admin:propipe admin:company user:company',
//  });

// Turbulent Research - test environment configuration - end

export default {
    getUserInfo(token: string) {
        console.log('getuserinfo');
        return new Promise((resolve, reject) => {
            auth0.client.userInfo(token, (err, user) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(user);
                }
            });
        });
    },
    parseHash(hash: string) {
        console.log('parsehash');
        return new Promise((resolve, reject) => {
            auth0.parseHash({
                hash,
            }, (err, result) => {
                console.log('callback');
                if (err) {
                    reject(err);
                }
                if (result) {
                    resolve(result);
                }
            });
        });
    },
    login(username: string, password: string) {
        // this needs AuthHandler to handle the redirect
        return new Promise((resolve, reject) => {
            auth0.login({
                realm: userDb,
                username,
                password,
            }, (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            });
        });
    },
    refreshToken() {
        return new Promise((resolve, reject) => {
            auth0.checkSession({}, (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            });
        });
    },
    resetPassword(email: string) {
        const apiClient = axios.create({
            baseURL: 'https://propipe-prod.us.auth0.com',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        return apiClient.post('/dbconnections/change_password', {
            client_id: clientId,
            email,
            connection: userDb,
        });
    },
};
